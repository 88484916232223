// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    VERSION: require('../../package.json').version,
    AUTHPASS: btoa('test:test'),
    FILE_UPLOAD_MAX_SIZE: 5097152,
    GUI_TYPE: '0',
    INTERCOM_KEY: 'qf1qluis',
    TAWK_KEY: '5f10115f5b59f94722bad505',
    matomoEnabled: false,
    auth: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/auth',
        port: '',
        url: '/booking/oauth/token',
    },
    captcha: {
        url: '/booking/captcha',
    },
    erp: {
        v1: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            apiPort: '',
            apiUrl: '/booking/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            apiPort: '',
            apiUrl: '/booking/erp/api',
        },
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/asv2/assistance/api',
        },
    },
    odoo: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/asv2/odo/api',
    },
    document: {
        v1: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/erp/docapi',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/erp/docapi',
        },
    },
    authorization: {
        v1: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/authorization/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/authorization/api',
        },
    },
    access: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/access',
    },
    onesignal: {
        appId: 'd5508427-9f86-4d8c-9433-d92f23293280',
        allowLocalhostAsSecureOrigin: false,
        defaultUrl: 'https://test-asistent.medifit.si',
        defaultTitle: 'asistent | Medifit | TEST',
        safariWebId: 'web.onesignal.auto.1a1cd43f-104c-4ac2-b069-e07775f1eeb9',
    },

    root: {
        v3: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/asv2',
        },
    },
    aircall: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/eav2/erp/aircallapi',
    },
};
